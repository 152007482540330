export default {
  pages: {
    clientsShow: {
      secondaryTitle: 'Клиент',
      tabs: ['Требования', 'Заявки'],
      clientRequirements: {
        fixReasons: {
          title: 'Надо поправить данные',
        },
        requirementsList: ['Файлы', 'Карта', 'Анкета'],
        sendToReview: 'Отправить на рассмотрение',
        filesTab: {
          alreadyUploaded: 'Загружено',
          passportPhoto: {
            title: 'Фото паспорта',
          },
          passportFacePhoto: {
            title: 'Фото паспорта на фоне лица',
          },
          idPassportPhoto: {
            title: 'Фото ID пасспорта',
          },
          idPassportFacePhoto: {
            title: 'Фото ID паспорта на фоне лица',
          },
          commonFiles: {
            secondaryTitle: 'Необязательно',
          },
        },
        questionnaireTab: {
          personalData: {
            title: 'Личная информация',
            fields: {
              activityArea: {
                label: 'Сфера деятельности',
              },
              preferredLanguage: {
                label: 'Предпочитаемый язык',
              },
              car: {
                label: 'Автомобиль',
                hasCar: 'Есть',
                doesNotHaveCar: 'Нет',
              },
            },
          },
          contacts: {
            title: 'Контакты',
            fields: {
              contactNumber: {
                label: 'Номер телефона',
              },
              person: {
                label: 'Кем является',
              },
              ownerName: {
                label: 'Имя владельца',
              },
            },
            addOneMoreContact: 'Добавить ещё контакт',
            save: 'Сохранить',
            edit: 'Изменить',
            cancel: 'Отмена',
          },
          labels: {
            car: 'Автомобиль',
            hasCar: 'Есть',
            hasNoCar: 'Нет',
          },
        },
        cardTab: {
          title: 'Карта клиента',
          addMore: 'Добавить ещё',
          smsWasSentToMessage: 'СМС-код был отправлен на номер клиента ',
          addAnotherCardWarning: 'Добавить другую карту',
          fields: {
            cardNumber: {
              label: 'Номер карты',
            },
            expire: {
              label: 'Срок карты',
            },
            otp: {
              label: 'Код подтверждение',
            },
          },
          requestCreate: 'Добавить карту',
          create: 'Подтвердить',
          cancel: 'Отмена',
          infos: [
            'Имя на основной карте должно совпадать с именем клиента',
            'Можно добавить несколько карт, для увелечения лимита рассрочки',
          ],
          cardExpiresSoonAlert: 'Истекает срок карты',
        },
      },
    },
  },
};
