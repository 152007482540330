export default {
  pages: {
    updateClientPassport: {
      documentTitle: 'Обновление пасспорта',
      pageTitle: 'Обновление пасспорта',
      updatePassportFiles: {
        passport: {
          title: 'Паспорт клиента',
          uploadPhotoMessage: 'Загрузите фото пасспорта клиента',
          fields: {
            passportPhoto: {
              label: 'Фото пасспорта',
            },
            passportFace: {
              label: 'Паспорт на фоне лица',
            },
          },
          continue: 'Продолжить',
        },
        idPassport: {
          title: 'ID карта',
          uploadPhotoMessage: 'Загрузите фото ID карты клиента',
          fields: {
            passportIdPhotoFront: {
              label: 'Передняя сторона ID карты',
            },
            passportIdPhotoBack: {
              label: 'Задняя сторона ID карты',
            },
            passportIdPhoto: {
              label: 'ID карта на фоне лица',
            },
          },
          submit: 'Продолжить',
        },
      },
    },
  },
};
